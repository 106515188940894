import React from "react"
import { Helmet } from "react-helmet"
import TopBar from "../components/topBar";

import '../styles/App.scss';

import logo from '../images/idleonLogo.png';
import card from '../images/Ccards8.png';
import card2 from '../images/Ccards22.png';
import card3 from '../images/Ccards36.png';
import characters from '../images/CharacterHorizontal2.png'
import map from '../images/TitlecardMapNEW.png';
import mid from '../images/ForestNicer.png';
// import midO from '../images/mid_layer.png';
import bg from '../images/Bg12.png';
import bg11 from '../images/Bg11.png';
// import bg10 from '../images/Bg10.png';
import bg3 from '../images/Bg32.png';
import bg2 from '../images/Bg9.png';
import bgcover from '../images/BgDrop.png';
import cloud1 from '../images/cloud1.png';
import cloud2 from '../images/cloud2.png';
import rightBg from '../images/Icons_Abilities3c.png';
import coconutIdle from '../images/CoconutMoving.gif';
import mammothIdle from '../images/MammothMoving.gif';
import frogIdle from '../images/BullfrogMove.gif';
import carrotIdle from '../images/CarrotMoving.gif';
import crabIdle from '../images/SandcastleMoving.gif';
import preRegister from '../images/googlePlay3.png';
import iosButton from '../images/iosButton.svg';
import steamButton from '../images/steamButton.png';


import { OutboundLink } from "gatsby-plugin-google-analytics";
import BotBar from "../components/BotBar";
import { Link } from "gatsby";

export default function Home() {
  const seo = {
    title: "Legends of Idleon | The Free Idle MMO",
    description: "Form your own guild of unique characters in Legends of Idleon! With dozens of classes, monsters, and skills, there’s no end to the fun you’ll have!",
    image: "https://www.LegendsOfIdleon.com/share.png",
    url: "https://www.LegendsOfIdleon.com",
  }

  const homePageText = [
    ["An Idle MMO where you’re in Control",
    "Form your own guild of characters, who all continue to play even when you’re gone! Whether you’ve got a few minutes to play or the whole day, Legends of Idleon provides the fun MMO experience you’re looking for, alone or with friends!"],
    ["Create your legends",
    "With 38 classes and 600+ unique skills, the possible synergies are endless! Maybe you’ll make a Barbarian Fisherman who catches sea monsters, for your Druid Alchemist to make into powerful potions, which boost the critical damage of your Hunter Assassin so he can defeat the World Boss, unlocking even more content!"],
    ["Explore a World of Fun",
    "With monsters like moonwalking carrots, giant bullfrogs, and prancing coconuts, who knows what crazy things you’ll encounter outside of Town! Maybe you’ll meet a new Quest-Giver, or find a hidden shop, or even discover a secret area with a unique boss!"],
    ["What are you waiting for?"],
  ]


  return (
    <div className="AppContainer" style={{ backgroundImage: `url(${rightBg})` }}>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content="Play Legends of Idleon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />


        <link rel="icon" type="image/png" href="./favicon64.png" />
        <link rel="apple-touch-icon" href="./logo192_a.png" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap" rel="stylesheet"></link>

        {/*TODO <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.LegendsOfIdleon.com",
                "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                "name": "Legends of Idleon",
              }
            `}
        </script> */}
      </Helmet>
      <div className="LeftGradient" />
      <div className="RightGradient" />
      <div className="App">
        <div className="Content">
          <div className="Header">
            <div className="bg-container">
              <img src={bg} className="Pixel App-bg" alt="logo" />
              <img src={bg2} className="Pixel App-bg2" alt="logo" />
              <img src={cloud1} className="Cloud" style={{ left: "50px", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud2} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud1} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud2} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud1} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud2} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud1} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud2} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud1} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud2} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
              <img src={cloud1} className="Cloud" style={{ animationDelay: (Math.random() * 20 - 10) + "s", top: (5 + Math.random() * 60) + "%" }} alt="logo" />
            <img src={mid} className="Pixel App-mid" alt="logo" />
            </div>

            <header className="App-header">
              <img src={logo} className="Pixel Logo" alt="logo" />
              {/* <img src={card} className="Pixel App-logo" alt="logo" /> */}
              <div className="HomeOptionsGroup">
                <OutboundLink target="_blank" rel="noopener noreferrer" className="playButtonIos" href="https://apps.apple.com/us/app/idleon-idle-mmo/id1636526901">
                <img className="playButton" src={iosButton}/>
                </OutboundLink>
                <OutboundLink target="_blank" rel="noopener noreferrer" className="playButtonCont" href="https://play.google.com/store/apps/details?id=com.lavaflame.MMO">
                <img className="playButton" src={preRegister}/>
                </OutboundLink>
                <OutboundLink target="_blank" rel="noopener noreferrer" className="playButtonCont" href="https://store.steampowered.com/app/1476970/Legends_of_Idleon_MMO/">
                <img className="playButton" src={steamButton}/>
                </OutboundLink>
              </div>
              <OutboundLink className="PlayBrowserButton" href="/ytGl5oc/">
              <div className="PlayBrowserText">Play on the Web</div>
              <div className="PlayBrowserText2"></div>
              </OutboundLink>
              <OutboundLink className="SubtleLink" href="https://store.steampowered.com/app/1476970/Legends_of_Idleon_MMO/"><div className="PlayText2">Download now on Steam -- Play Cross Platform!</div></OutboundLink>
              <iframe className="SteamIframe" src="https://store.steampowered.com/widget/1476970/" frameborder="0"></iframe>
            </header>
            <TopBar selected="index"/>
          </div>


          <div className="Page1">
            <div  className="oversizeContainer" >
            <img src={map} className="Pixel imgbg oversize" />
            </div>
            <div className="Page1Shadow"/>
            <div className="Page1Shadow2"/>
            <img src={bgcover} className="Pixel headerDrop" />
            <img className="Pixel BannerImage" src={characters} />
            <div className="TextBox overlayText">
              <h2>
                {homePageText[0][0]}
              </h2>
              <p>
                {homePageText[0][1]}
              </p>
            </div>
          </div>

          <div className="Page2">
            <img src={bg11} className="imgbg blur" />
            <div className="childDiv">
              <div className="Card"><img src={card3} /></div>
              <div className="Card"><img src={card} /></div>
              <div className="Card"><img src={card2} /></div>
            </div>
            <div className="childDiv TextBox">
              <h2>
                {homePageText[1][0]}
              </h2>
              <p>
                {homePageText[1][1]}
              </p>
            </div>
          </div>

          <div className="Page3">
            <img src={bg} className="imgbg blur" />
            <div className="TextBox">
              <h2>
                {homePageText[2][0]}
              </h2>
              <p>
                {homePageText[2][1]}
              </p>
            </div>
            <div className="mapContainer">
              <img className="Pixel map" src={coconutIdle} />
              <img className="Pixel map" src={mammothIdle} />
              <img className="Pixel map" src={frogIdle} />
              <img className="Pixel map" src={carrotIdle} />
              <img className="Pixel map" src={crabIdle} />
            </div>
          </div>

          <div className="Page4">
            <img src={bg3} className="imgbg" />
            <div>
              <h2 className="waitingFor">
                {homePageText[3][0]}
    </h2>
              {/* <h2 className="PlayButtonBot">
                Play for Free
    </h2> */}

                <OutboundLink className="playButtonCont" href="https://play.google.com/store/apps/details?id=com.lavaflame.MMO">
                <img className="playButton" src={preRegister}/>
                </OutboundLink>
            </div>
          </div>
                <BotBar/>


        </div>
      </div>
    </div>
  );
}
